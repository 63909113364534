/*============================================================================
Dependency-free breakpoint mixin
// http://blog.grayghostvisuals.com/sass/sass-media-query-mixin/
==============================================================================*/
/*============================================================================
#Breakpoint and Grid Variables
==============================================================================*/
/* 769*/
/* 1025*/
/*================ The following are dependencies of csswizardry grid ================*/
.uk-navbar {
  position: fixed !important;
  z-index: 3;
  width: 100%; }

.uk-cover {
  height: 100% !important; }

.ad_navicon_toggle {
  display: none; }

/* When the screen is less than 800 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 800px) {
  .ad_navicon_toggle {
    display: block; } }

@media screen and (max-width: 800px) {
  .ad_navtext_toggle {
    display: none; } }

nav.uk-navbar-container.uk-navbar {
  top: 0px;
  left: 0px;
  width: 100% !important;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0.5px 5px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0.5px 5px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0.5px 5px -1px rgba(0, 0, 0, 0.5); }

.uk-background-cover.uk-height-medium.uk-panel.uk-flex.uk-flex-center.uk-flex-middle {
  height: 100vh; }

#text-white {
  color: #ffffff;
  font-weight: 220;
  text-align: center; }

.uk-overlay-primary {
  background: rgba(34, 34, 34, 0.35) !important; }

#centerTop {
  width: 100%;
  z-index: 1; }

h1 {
  font-size: 3em; }

h1#text-white {
  font-size: 3em; }

h4#text-grey {
  padding-top: 111.1117px;
  padding-top: 7em;
  border: none;
  color: lightgrey;
  font-weight: 220;
  text-align: center; }

.uk-dropdown.uk-open.uk-dropdown-bottom-left {
  margin-top: 0px;
  margin-top: 0em; }

.uk-article-title, .center-align {
  text-align: center; }

span {
  width: 100%;
  margin: 0 auto; }

a.uk-icon-button.uk-margin-small-right.moreButton.uk-icon {
  float: right;
  margin-top: -19.04772px;
  margin-top: -1.2em;
  position: relative;
  background-color: #1e87f0;
  color: #ffffff; }
  a.uk-icon-button.uk-margin-small-right.moreButton.uk-icon:hover {
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
    transition: 0.5s;
    background-color: #0f7ae5; }

.uk-tile.uk-tile-muted {
  border-bottom: 1em solid #a9a9a9;
  padding: 0px;
  padding: 0em; }

.uk-subnav#subnav {
  float: right; }

a.uk-icon-link.linkedin.uk-icon {
  vertical-align: .5vh; }

span.uk-label {
  max-width: 8px;
  max-width: 8em;
  text-align: center;
  border-radius: 10px; }

jonathan > a {
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 80px;
  padding: 0 15px;
  font-size: .875rem;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  text-decoration: none;
  text-transform: uppercase; }
  jonathan > a:hover {
    color: #666;
    text-decoration: none; }

.uk-card {
  border-radius: 5px; }

.logo {
  width: 35px;
  height: auto; }

.uk-offcanvas-bar {
  background: #fff !important; }

li.uk-active a {
  color: #999 !important; }

hr {
  border-top: 1px solid #999 !important; }

.bg-img {
  background-position: center; }

li.uk-active a {
  padding: 1.5rem 0 !important; }

.uk-nav-default .uk-nav-divider {
  border-top: 1px solid #e5e5e5 !important; }

/*# sourceMappingURL=styles.css.map */